<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-join" >

    <VueDaumPostcode class="address-pop-layer"/>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap">
      <mbHeaderTitle :title="pageTitle"></mbHeaderTitle>

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="join-wrap">
          <h3 class="h3">회원가입</h3>
          <div class="join-tb-tit-box"><strong class="">회원정보</strong> <span>(   <em class="fnt-point">*</em>  표시는 필수입력 항목입니다. )</span></div>
          <div class="tb-01">
            <table>
              <colgroup><col width="170px"><col width="350px"><col width="170px"><col width="*"></colgroup>
              <tbody>
              <tr>
                <th><em>*</em>아이디</th>
                <td colspan="3">
                  <input type="text" class="input-st1 wd405" :class="!(validation.userId)?null:'st-error'" v-model="membInfo.userId" @blur="onBlur" name="userId" placeholder="아이디를 입력해주세요.">
                  <!-- 오류 일 경우 st-error 클래스를 추가해주세요 -->
                  <p v-if="validation.userId != null" class="txt-error">{{validation.userId}}</p>
                  <!-- 오류 일 경우 txt-error 메세지를 노출해주세요 -->
                </td>
              </tr>
              <tr>
                <th><em>*</em>비밀번호</th>
                <td colspan="3">
                  <input type="password" class="input-st1 wd405" :class="!(validation.password)?null:'st-error'" v-model="membInfo.password" @blur="onBlur" name="Pswd" placeholder="8~15자의 영문,숫자,특수문자 조합으로 입력해주세요.">
                  <!-- 오류 일 경우 st-error 클래스를 추가해주세요 -->
                  <p v-if="validation.password != null" class="txt-error">{{validation.password}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>비밀번호 확인</th>
                <td colspan="3">
                  <input type="password" class="input-st1 wd405" :class="!(validation.rePswd)?null:'st-error'" v-model="membInfo.rePswd" @blur="onBlur" name="rePswd" placeholder="비밀번호를 다시 한번 입력해주세요.">
                  <!-- 오류 일 경우 st-error 클래스를 추가해주세요 -->
                  <p v-if="validation.rePswd != null" class="txt-error">{{validation.rePswd}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>이름</th>
                <td colspan="3">
                  <input type="text" class="input-st1 wd405" :class="!(validation.name)?null:'st-error'" v-model="membInfo.name" @blur="onBlur" name="Name" placeholder="성함을 기재해주세요.">
                  <!-- 오류 일 경우 st-error 클래스를 추가해주세요 -->
                  <p v-if="validation.name != null" class="txt-error">{{validation.name}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>이메일</th>
                <td colspan="3" class="join-email-box">
                  <input type="text" class="input-st1 st-email" :class="!(validation.email)?null:'st-error'" v-model="membInfo.emailAccount" @blur="onBlur" name="Email" placeholder="이메일 입력"><span class="line"> @</span>
                  <select :class="!(validation.emailDomain)?null:'st-error'" v-model="membInfo.emailDomain" @blur="onBlur" name="Domain" class="input-st1 st-selectbox st-email2">
                    <option value="">선택</option>
                    <option value="daum.net">daum.net</option>
                    <option value="naver.com">naver.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="etc">직접입력</option>
                  </select>
                  <input v-if="membInfo.emailDomain == 'etc'" type="text" @blur="onBlur" name="Domain" v-model="membInfo.emailDomainEtc" class="input-st1 st-email3" placeholder="이메일 입력"/>
                  <p v-if="validation.email != null" class="txt-error">{{validation.email}}</p>
                  <p v-if="validation.emailDomain != null" class="txt-error">{{validation.emailDomain}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>휴대폰번호</th>
                <td colspan="3" class="join-hp-box">
                  <input type="text" class="input-st1 wd405 st-hp" :class="!(validation.mobileNo)?null:'st-error'" @blur="onBlur" name="mobileNo" v-model="membInfo.mobileNo" onKeyup="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="“-” 없이 입력해주세요.">
                  <a href="javascript:;" class="btn-s" @click="sendAuthNo">인증번호 발송</a>
                  <p v-if="validation.mobileNo != null" class="txt-error">{{validation.mobileNo}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>인증번호</th>
                <td colspan="3">
                  <input type="text" class="input-st1 wd405" :class="!(validation.authNo)?null:'st-error'" @blur="onBlur" name="AuthNo" v-model="membInfo.authNo" placeholder="휴대폰으로 전송된 인증번호를 입력해주세요."
                         @input="onInputEvent" maxlength="6">
                  <p v-if="validation.authNo != null" class="txt-error">{{validation.authNo}}</p>
                </td>
              </tr>
              <tr>
                <th><em>*</em>생년월일</th>
                <td colspan="3" class="st-year">
                  <select class="input-st1 st-selectbox st-year" v-model="membInfo.birthYY" >
                    <option value="">년도</option>
                    <option v-for="yyyy in yyyyList" :key="yyyy">{{yyyy}}</option>
                  </select>
                  <span class="line">년</span>
                  <select class="input-st1 st-selectbox st-month" v-model="membInfo.birthMM" >
                    <option value="">월</option>
                    <option v-for="mm in mmList" :key="mm">{{mm}}</option>
                  </select>
                  <span class="line">월</span>
                  <select class="input-st1 st-selectbox st-day" v-model="membInfo.birthDD" >
                    <option value="">일</option>
                    <option v-for="dd in ddList" :key="dd">{{dd}}</option>
                  </select>
                  <span class="line">일</span>
                  <input type="radio" class="ra-st1" name="birth_typ" id="ra0102" v-model="membInfo.lunarBirth" value=false><label for="ra0102">양력</label>
                  <input type="radio" class="ra-st1" name="birth_typ" id="ra0101" v-model="membInfo.lunarBirth" value=true><label for="ra0101">음력</label>
                  <div class="tb-exp">주민등록상 생년월일을 입력해주세요.</div>
                </td>
              </tr>
              <tr>
                <th><em>*</em>자택주소</th>
                <td colspan="3">
                  <div class="st-addr-box">
                    <a href="javascript:;" @click="openZipCode" class="btn-s ml-none">우편번호</a>
                    <input type="text" @click="openZipCode" class="input-st1 st-addr1" placeholder="" v-model="membInfo.zipCode">
                    <input type="text" @click="openZipCode" class="input-st1 st-addr2" placeholder="" v-model="membInfo.address">
                    <input type="text" class="input-st1 st-addr3" placeholder="상세 주소를 입력해주세요." v-model="membInfo.addressDetail">
                  </div>
                </td>
              </tr>
              <tr>
                <th><em>*</em>최종퇴직부서</th>
                <td class="td-st2"><input type="text" class="input-st1 wd100per" placeholder="전자 재임시 최종부서를 입력해주세요." v-model="membInfo.retirementDepartment"></td>
                <th><em>*</em>재임시 직위</th>
                <td><input type="text" class="input-st1 wd100per" placeholder="재임시 직위를 작성해주세요." v-model="membInfo.retirementPosition"></td>
              </tr>
              <tr>
                <th><em></em>현재 직장</th>
                <td class="td-st2"><input type="text" class="input-st1 wd100per" placeholder="현재 직장명을 작성해주세요." v-model="membInfo.company">
                  <div class="tb-exp red">경쟁사 근무, 징계 등으로 구분 시 <br>가입이 제한될 수 있습니다.</div></td>
                <th><em></em>현재 직위</th>
                <td><input type="text" class="input-st1 wd100per" placeholder="현재 직장에서의 직위를 작성해주세요." v-model="membInfo.position"></td>
              </tr>
              <tr>
                <th><em>*</em>희망분과</th>
                <td colspan="3">
                  <select class="input-st1 st-selectbox st-year" v-model="membInfo.departments">
                    <option value="">선택</option>
                    <option v-for="(department, idx) in departmentItems" :key="idx" :value="department.groupId">{{ department.groupName }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><em>*</em>희망동호회<br><span class="tit-s">(중복선택가능)</span></th>
                <td colspan="3">
                  <ul class="check-list-box">
                    <li v-for="(club, idx) in clubItems" :key="idx">
                      <input type="checkbox" :id="'chk010'+idx" :value="club.groupId" class="chk-st1" v-model="membInfo.clubs">
                      <label :for="'chk010'+idx">{{ club.groupName }}</label>
                    </li>
<!--                    <li><input type="checkbox" id="chk0110" class="chk-st1"><label for="chk0110">기타(보류)</label></li>-->
                  </ul>

                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="join-tb-tit-box"><strong class="">청탁금지법 대상 여부</strong> <span class="fnt-point">[필수]</span></div>
          <div class="join-agree-box">
            <div class="left-box">
              <input type="radio" name="target" id="ra0201" v-model="membInfo.antiGraftLawTarget" value=true @change="onClickTarget" class="ra-st1"><label for="ra0201">대상자</label>
              <input type="radio" name="target" id="ra0202" v-model="membInfo.antiGraftLawTarget" value=false class="ra-st1"><label for="ra0202">비대상자</label>
            </div>
            <div class="right-box"><a href="javascript:;" class="dg-pop-type-ov" @click="popupAgree1(true)">청탁금지법 대상자 안내</a></div>
          </div>

          <div class="join-tb-tit-box"><strong class="">약관동의</strong></div>
          <div class="join-agree-box mb-none">
            <div class="left-box">
              <input type="checkbox" id="chk0301" class="chk-st1" v-model="checkAll" @change="onClickCheckAll"><label for="chk0301">이용약관 전체 동의</label>
            </div>
          </div>
          <div class="join-agree-sub-box">
            <div class="row-box">
              <div class="left-box">
                <input type="checkbox" id="chk0302" class="chk-st1" v-model="membInfo.personalInformationAgreement" @change="onClickCheck"><label for="chk0302"><em class="point">[필수]</em>  개인정보 수집 및 이용에 대한 동의</label>
              </div>
              <div class="right-box"><a href="javascript:;" id="#popJoinAgreeTxtBox02" class="dg-pop-type-ov" @click="popupAgree2(true)">약관보기</a></div>
            </div>
            <div class="row-box">
              <div class="left-box">
                <input type="checkbox" id="chk0303" class="chk-st1" v-model="membInfo.clauseAgreement" @change="onClickCheck"><label for="chk0303"><em class="point">[필수]</em>  전자사랑 모임 약관에 동의</label>
              </div>
              <div class="right-box"><a href="javascript:;" id="#popJoinAgreeTxtBox03" class="dg-pop-type-ov" @click="popupAgree3(true)">약관보기</a></div>
            </div>
          </div>

          <div class="btn-box"><a href="javascript:;" @click="onClickComplete" class="btn-submit">회원가입 완료</a></div>

        </div>

      </div>
      <!-- class : main-wrap (E) -->

      <Agree1Popup v-if="agree1" @click:bgDimClose="popupAgree1"></Agree1Popup>
      <Agree2Popup v-if="agree2" @click:bgDimClose="popupAgree2"></Agree2Popup>
      <Agree3Popup v-if="agree3" @click:bgDimClose="popupAgree3"></Agree3Popup>

    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import Agree1Popup from '@/views/login/popup/agree1_popup'
import Agree2Popup from '@/views/login/popup/agree2_popup'
import Agree3Popup from '@/views/login/popup/agree3_popup'
import UserApi from '@/api/UserApi'
import VueDaumPostcode from "@/views/component/vue-daum-postcode"
import groupApi from "@/api/GroupApi";
import mbHeaderTitle from '@/layout/components/Header/mbHeaderTitle';

export default {
  name: "join",
  components:{
    Agree1Popup,
    Agree2Popup,
    Agree3Popup,
    VueDaumPostcode,
    mbHeaderTitle
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    },
    pageTitle:{
      type:String,
      default:'회원가입'
    },
  },
  data(){
    return{
      yyyyList:[],
      mmList:[],
      ddList:[],
      checkAll:false,
      agree1:false,
      agree2:false,
      agree3:false,
      isSent:false,
      membInfo:{
        userId:'',
        password:'',
        rePswd:'',
        name:'',
        email:'',
        emailAccount:'',
        emailDomain:'',
        emailDomainEtc:'',
        mobileNo:'',
        authNo:'',
        birthDate:'',
        birthYY:'',
        birthMM:'',
        birthDD:'',
        lunarBirth:false,
        zipCode:'',
        address:'',
        addressDetail:'',
        retirementDepartment:'',
        retirementPosition:'',
        company:'',
        position:'',
        departments:'',
        clubs:[],
        antiGraftLawTarget:false,
        personalInformationAgreement:false,
        clauseAgreement:false,
        userHasGroups:[],
        state:'STATE_REQUEST'
      },
      departmentItems:[],
      clubItems:[],

      validation:{
        userId:'',
        password:'',
        rePswd:'',
        name:'',
        email:'',
        emailDomain:'',
        mobileNo:'',
        authNo:null
      }
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.agree1=false
        this.agree2=false
        this.agree3=false
      }
    }
  },
  methods:{
    popupAgree1(e){
      this.agree1 = e
      this.$emit('click:bgDim', this.agree1)
    },
    popupAgree2(e){
      this.agree2 = e
      this.$emit('click:bgDim', this.agree2)
    },
    popupAgree3(e){
      this.agree3 = e
      this.$emit('click:bgDim', this.agree3)
    },
    onClickCheckAll(){
      if(this.checkAll){
        this.membInfo.personalInformationAgreement=true
        this.membInfo.clauseAgreement=true
      }else{
        this.membInfo.personalInformationAgreement=false
        this.membInfo.clauseAgreement=false
      }
    },
    onClickCheck(){
      if(this.membInfo.personalInformationAgreement&&this.membInfo.clauseAgreement){
        this.checkAll = true
      }else{
        this.checkAll = false
      }
    },
    onClickTarget(){
      alert('※본인 혹은 배우자가 청탁금지 대상자에 해당 될 경우 \n' +
          '추후 삼성전자 측에서 제공되는 경조사 및 각종 지원 등이 \n' +
          '제한 될 수 있습니다 해당 내용은 전자사랑모임 사무실 측으로 \n' +
          '연락 주시면 보다 자세한 설명 드리도록 하겠습니다 \n' +
          '(TEL.02-558-3401)')
    },
    sendAuthNo(){
      if(this.membInfo.mobileNo == ''){
        this.validation.mobileNo = '휴대폰번호를 입력해주세요.';
      }else if(this.validation.mobileNo != null){
        return;
      }else if(this.validation.mobileNo == null){
        this.isSent = true;
        this.validation.authNo = '인증번호를 입력해주세요.';

        UserApi.existsMobile(this.membInfo.mobileNo).then((data)=>{
          if(!data){
            this.isSent = true;
            this.validation.mobileNo = null;

            UserApi.sendMobileAuthNo(this.membInfo.mobileNo).then(()=>{
              return alert("인증번호를 발송했습니다.\n인증번호가 오지 않으면 정보를 \n다시 확인해주세요.")
            }).catch(()=>{
              return alert("오류가 발생하였습니다. 다시 시도해주세요.");
            });
          }else{
            this.validation.mobileNo = '이미 사용중인 핸드폰번호 입니다.';
            return alert('이미 존재하는 회원입니다.\n가입 정보 문의는 사무실 측으로 연락주세요. (TEL.02-558-3401)');
          }
        })
      }
    },
    onInputEvent(e){
      this.membInfo.authNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      if(!this.isSent) {
        this.validation.authNo = '인증번호를 발송해주세요.'
      }else if(e.target.value==''){
        this.validation.authNo = '인증번호를 입력해주세요.';
      }else {
        UserApi.checkMobileAuthNo(this.membInfo.mobileNo, e.target.value).then(data => {
          if (data) {
            this.validation.authNo = null;
          } else {
            this.validation.authNo = '인증번호를 다시 확인해주세요.';
          }
        })
      }
    },
    openZipCode() {
      let _user = this.membInfo;
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          _user.zipCode = data.zonecode;
          _user.address = data.address;
        }}).open();
    },
    async duplicatedCheck(userId) {
      const isUsed = await UserApi.existsId(userId)
      if (isUsed) {
        this.validation.userId = '이미 사용중인 아이디입니다.';
        return false;
      }
      return true;
    },
    onBlur(e){
      this.formatValidation(e);
    },
    formatValidation(e){
      let idRegExp = /^[a-zA-Z0-9]{5,20}$/;
      let nameRegExp = /^[가-힣a-zA-Z]+$/;
      let passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
      let emailRegExp = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])/;

      if( e.target.name === 'userId' ){
        if(e.target.value === ''){
          this.validation.userId = '아이디를 입력해주세요.'
        }else if(!idRegExp.test(e.target.value)){
          this.validation.userId = '아이디는 영문 및 숫자 5~20자리로 입력해주세요.'
        }else{
          this.duplicatedCheck(e.target.value)
          this.validation.userId = null
        }
      }else if( e.target.name === 'Pswd' ){
        if(e.target.value === ''){
          this.validation.password = '비밀번호를 입력해주세요.'
        }else if(!passwordRegExp.test(e.target.value)){
          this.validation.password = '비밀번호는 8~15자 영문, 숫자, 특수문자 조합으로 입력해주세요.'
        }else{
          this.validation.password = null
        }
      }else if( e.target.name === 'rePswd' ){
        if(e.target.value === ''){
          this.validation.rePswd = '비밀번호를 입력해주세요.'
        }else if(this.membInfo.rePswd === this.membInfo.password){
          this.validation.rePswd = null
        }else {
          this.validation.rePswd = '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해주세요.'
        }
      }else if( e.target.name === 'Name' ){
        if(e.target.value === ''){
          this.validation.name = '이름을 입력해주세요.'
        }else if(!nameRegExp.test(e.target.value)){
          this.validation.name = '이름은 한글, 영문만 사용가능합니다.'
        }else{
          this.validation.name = null
        }
      }else if( e.target.name === 'Email' ){
        if(e.target.value === ''){
          this.validation.email = '이메일을 입력해주세요.'
        }else if(!emailRegExp.test(e.target.value)){
          this.validation.email = '이메일 형식이 올바르지 않습니다.'
        }else if(this.membInfo.emailDomain === ''){
          this.validation.email = null
          this.validation.emailDomain = '이메일을 입력해주세요.'
        }else{
          UserApi.existsEmail(e.target.value+'@'+this.membInfo.emailDomain).then((data)=>{
            if(!data){
              this.validation.email = null
            }else{
              this.validation.email = '이미 사용중인 이메일 입니다.'
            }
          })
        }
      }else if( e.target.name === 'Domain' ) {
        if (e.target.value === '') {
          this.validation.emailDomain = '이메일을 입력해주세요.'
        }else if(this.membInfo.emailAccount === ''){
          this.validation.email = '이메일을 입력해주세요.'
          this.validation.emailDomain = null
        }else {
          UserApi.existsEmail(this.membInfo.emailAccount+'@'+e.target.value).then((data)=>{
            if(!data){
              this.validation.email = null
              this.validation.emailDomain = null
            }else{
              this.validation.email = '이미 사용중인 이메일 입니다.'
            }
          })
        }
      }else if( e.target.name === 'mobileNo' ) {
        this.isSent = false;

        if (e.target.value === '') {
          this.validation.mobileNo = '휴대폰번호를 입력해주세요.'
        }else if(e.target.value.length<8 || e.target.value.length>12){
          this.validation.mobileNo = '휴대폰번호를 확인해주세요.'
        }else {
          this.validation.mobileNo = null
          this.validation.authNo = '인증번호를 발송해주세요.'
        }
      }
    },
    onClickComplete(){
      if(this.validation.userId == null && this.validation.password == null && this.validation.rePswd == null
          && this.validation.name == null && this.validation.email == null && this.validation.emailDomain == null
          && this.validation.mobileNo == null && this.validation.authNo == null
          && this.membInfo.authNo != '' && this.membInfo.birthYY != ''
          && this.membInfo.birthMM != '' && this.membInfo.birthDD != '' && this.membInfo.retirementDepartment != ''
          && this.membInfo.retirementPosition != '' && this.membInfo.departments != '' && this.membInfo.clubs.length > 0) {

        if(!this.membInfo.personalInformationAgreement || !this.membInfo.clauseAgreement){
          alert('약관 동의를 해주세요.')
        }else{
          this.membInfo.birthDate = this.membInfo.birthYY+"-"+this.membInfo.birthMM+"-"+this.membInfo.birthDD
          this.membInfo.emailDomain = this.membInfo.emailDomain!='etc'?this.membInfo.emailDomain:this.membInfo.emailDomainEtc
          this.membInfo.email = ''
          this.membInfo.email = this.membInfo.emailAccount + "@" + this.membInfo.emailDomain
          this.membInfo.userHasGroups = []
          this.membInfo.userHasGroups = this.membInfo.clubs;
          this.membInfo.userHasGroups.push(this.membInfo.departments);

          UserApi.save(this.membInfo).then((data)=>{
            if(data != null){
              this.$router.push({name:'joinResult',params:{name:data.name}})
            }
          }).catch(()=>{
              alert('회원 등록 중 오류가 발생하였습니다. \n오류가 계속되면 운영자에게 문의해주세요.')
          })
        }
      }else{
        alert('회원정보 필수값을 입력해주세요.')
      }
    }
  },
  created() {
    for(let i=1930; i<2000; i++){
      this.yyyyList.push(i);
    }
    for(let i=1; i<=12; i++){
      let month = i;
      if(month<10){
        month = '0'+month
      }
      this.mmList.push(month);
    }
    for(let i=1; i<=31; i++){
      let day = i;
      if(day<10) {
        day = '0'+day
      }
      this.ddList.push(day);
    }

    groupApi.flatList(1).then(data => {
      this.departmentItems = data;
    });
    groupApi.flatList(2).then(data => {
      this.clubItems = data;
      //this.clubItems.push({groupName:'기타(보류)',groupId:'999'})
    });

  },
}
</script>

<style scoped>
@media screen and (max-width: 640px) {
  .btn-top{display: none !important}
}
.tb-01 tbody td .tb-exp{font-size: 13px;}
</style>