import axios from 'axios';

class GroupApi {
    constructor() {
        this.endpoint =  process.env.VUE_APP_BASE_API;
    }

    list(parentId) {
        return axios.get(`${this.endpoint}/group/list/${parentId??''}`, {}).then(response=>response.data);
    }

    managerList() {
        return axios.get(`${this.endpoint}/user/getSms/manager/`, {}).then(response=>response.data);
    }

    flatList(parentId) {
        return axios.get(`${this.endpoint}/group/flat/list/${parentId??''}`, {}).then(response=>response.data);
    }

    getGroup(groupId) {
        return axios.get(`${this.endpoint}/group/${groupId}`, {}).then(response=>response.data);
    }

    groupNameList(parentId) {//1:분과 / 2:동호회
        return axios.get(`${this.endpoint}/user/getSms/groups?parentId=${parentId??''}`, {}).then(response=>response.data);
    }

    mangerNameList() {//운영진
        return axios.get(`${this.endpoint}/user/getSms/manager`, {}).then(response=>response.data);
    }


    save(group) {
        return axios.post(`${this.endpoint}/group/save`, group).then(response=>response.data);
    }

    deleteGroup(groupId) {
        return axios.delete(`${this.endpoint}/group/delete/${groupId}`, {}).then(response=>response.data);
    }

    moveGroup(move, destination, position) {
        let param = {
            move: move,
            destination: destination,
            position: position
        }
        axios.put(`${this.endpoint}/group/move`, param).then(response=>response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/group/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

}

export default new GroupApi();
