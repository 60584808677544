<template>

  <div class="dg-pop-layer on pop-join-agree-wrap" id="popJoinAgreeTxtBox01">
    <div class="inner-box">
      <div class="tit-box">청탁금지법 대상자 안내</div>
      <div class="cont-box iscroll2">
        법&nbsp;적용&nbsp;대상&nbsp;기관은&nbsp;△국회, 법원, 현법재판소, 선거관리위원회, 중앙행정기관&nbsp;및&nbsp;그&nbsp;소속<br />
        기관,지방자치단체, 시.도&nbsp;교육청, 공직유관단체, 공공기관&nbsp;운영법&nbsp;제4조에&nbsp;따른&nbsp;기관&nbsp;<br />
        △각급 학교,사립학교법에&nbsp;따른&nbsp;학교법인,&lsquo;언론중재&nbsp;및&nbsp;피해구제&nbsp;등에&nbsp;관한&nbsp;법률&rsquo;에&nbsp;따른&nbsp;언론사&nbsp;<br />
        등이다.&nbsp;<br />
        법에&nbsp;정의된&nbsp;언론사는&nbsp;방송사업자, 신문사업자, 잡지&nbsp;등&nbsp;정기간행물사업자, 뉴스통신<br />
        사업자&nbsp;및&nbsp;인터넷신문사업자다.<br />
        적용&nbsp;대상자는&nbsp;공직자&nbsp;또는&nbsp;공적&nbsp;업무&nbsp;종사자를&nbsp;&lsquo;공직자&nbsp;등&rsquo;으로&nbsp;정의한다.<br />
        직원은&nbsp;근로계약&nbsp;형태와&nbsp;상관없이&nbsp;공공기관과&nbsp;근로계약을&nbsp;체결한&nbsp;근로자는&nbsp;모두&nbsp;해당된다.<br />
        공직자&nbsp;등의&nbsp;배우자도&nbsp;포함된다.&nbsp;<br />
        공공기관의&nbsp;의사결정&nbsp;등에&nbsp;참여하는&nbsp;민간인인&nbsp;&lsquo;공무수행&nbsp;사인(私人)&rsquo;도&nbsp;적용&nbsp;대상이다.
      </div>
      <div class="btn-box"><a href="javascript:;" class="btn-submit dg-pop-close" @click="closePopup">확인</a></div>
    </div>
  </div>


</template>

<script>
export default {
  name: "Agree1Popup",
  data() {
    return {
    }
  },
  methods:{
    closePopup(){
      this.$emit('click:bgDimClose',false)
    }
  }
}
</script>

<style scoped>

</style>